@import url("ex.css");

/* ------------ Link Color Settings ------------ */
a {
    color: #ff3300;
    text-decoration: none;
    background-color: transparent;
}
a:visited {
    color: #b25757;
    background-color: transparent;
}
a:hover {
    color: #f60;
    text-decoration: underline;
    background-color: transparent;
}
/* ------------ HTML TAG Re-definition ------------ */
body {
    background-color: #ffffff;
    color: #666666;
    font-family: Verdana, Helvetica, "ＭＳ Ｐゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", osaka, sans-serif;
    font-size: 12px;
    margin: 0;
    padding: 0;
}
table {
    width: 100%;
    margin: 0;
    padding: 0;
}
table td {
    padding: 0;
    margin: 0;
    vertical-align: top;
    line-height: 130%;
    font-size: 12px;
}
img {
    border: 0;
}

/* ------------ Heading and List tag Settings ------------ */
h1,
h2,
h3,
h4,
h5 {
    font-family: "Trebuchet MS", "Lucida Grande", Verdana, Arial, "Hiragino Kaku Gothic Pro", osaka, sans-serif;
    color: #999;
    background: inherit;
    padding: 0;
    margin: 10px 0 5px;
}
h1 {
    clear: both;
    font-size: 150%;
    background: url(images/indent_h1.gif) no-repeat left center;
    padding: 7px 0 7px 33px;
    line-height: normal;
}
h2 {
    clear: both;
    font-size: 112%;
    background: url(images/indent_h2.gif) no-repeat left center;
    padding: 6px 0 6px 30px;
    border-bottom: 1px solid #ededed;
}
h3,
h4,
h5 {
    font-size: 100%;
}
h3 {
    font-size: 100%;
    background: url(images/indent_h3.gif) no-repeat left center;
    padding: 5px 0 5px 28px;
    border-bottom: 1px dotted #ededed;
}
ul,
li {
    margin: 0 0 0 10px;
    padding: 2px;
    text-align: left;
}
ol {
    margin: 0 0 0 18px;
    padding: 2px;
    text-align: left;
}
legend {
    padding: 0 10px;
    color: #f60;
    background: inherit;
}
hr {
    height: 1px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 0;
    background: url(images/dot_line.gif) repeat-x bottom;
}
legend {
    padding: 0 10px;
    color: #666;
    background: inherit;
}
textarea {
    width: 300px;
}
form {
    width: 100%;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

/* ------------ Layout settings ------------ */
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 800px;
    text-align: left;
    color: #666;
    background: #f6f8fe;
}

/* ------------ Header elements ------------ */
#header {
    background: url(images/header_back.gif) repeat-x;
    height: 177px;
}
#header .r_back {
    background: url(images/header_r_back.jpg) no-repeat right top;
}
#header .logo {
    display: block;
    height: 177px;
    background: url(images/header_logo.jpg) no-repeat left top;
    margin: 0;
    padding: 0;
}
#header .go_home {
    display: block;
    height: 130px;
    width: 450px;
    position: absolute;
    top: 5px;
    left: 0px;
    text-decoration: none;
}
#header .go_jnode {
    display: block;
    height: 65px;
    width: 65px;
    position: absolute;
    top: 20px;
    right: 28px;
    text-decoration: none;
}

#header .search_box {
    display: block;
    height: 22px;
    position: relative;
    top: 107px;
    right: 20px;
    text-align: right;
    white-space: nowrap;
    overflow: visible;
}
#header .search_box input[type="text"] {
    border: 1px solid #eeeeee;
    border-style: inset;
    padding: 2px;
    width: 170px;
    height: 16px;
}
#header .search_box a {
    font-size: 10px;
    color: #ffffff;
}

#header .sub {
    font-size: 10px;
    display: block;
    height: 27px;
    padding: 5px 20px 0 0;
    white-space: nowrap;
}

#header .menu {
    text-align: right;
}
#header .menu ul {
    text-align: right;
}
#header .menu li {
    display: inline;
    margin: 0;
}
#header .menu a {
    color: #0066cc;
    padding-left: 14px;
    background: url(images/header_menu_indent.gif) no-repeat left center;
}
#header .menu a:hover {
    color: #ff6600;
    padding-left: 14px;
    background: url(images/header_menu_indent2.gif) no-repeat left center;
}

/* ------------ footer ------------ */
#footer .links {
    clear: both;
    text-align: center;
    padding: 5px 0;
}
#footer .links a {
    display: inline-block;
    padding: 0 10px;
}
#footer .copyright {
    text-align: center;
    display: block;
    clear: both;
    margin-top: 20px;
    padding: 15px 0;
    font: 10px "Trebuchet MS", "Lucida Grande", Verdana, Arial, "Hiragino Kaku Gothic Pro", osaka, sans-serif;
    background: url(images/dot_bar.gif) repeat-x top;
}

/* ------------ Main page layout elements ------------ */
#main {
    margin: 0 auto;
    overflow: hidden;
}
#page_top {
    text-align: right;
}
#page_top a {
    margin: 10px 0 10px auto;
    display: block;
    width: 82px;
    height: 19px;
    text-decoration: none;
    background: url(images/page_top.gif) no-repeat;
}

/* ------------ xoops mainmenu & usermenu ------------ */
.mainmenu {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
}
.mainmenu li {
    margin: 0;
    padding: 0;
}
.mainmenu a,
.usermenu a {
    display: block;
    text-decoration: none;
    color: #666;
    background-color: inherit;
    margin-bottom: 4px;
    padding: 3px 3px 3px 28px;
    background: url(images/menu_indent.gif) no-repeat left center;
}
.mainmenu a.menuTop {
    padding-left: 28px;
}
.mainmenu a.menuMain {
    padding-left: 28px;
}
.mainmenu a:hover,
.usermenu a:hover {
    color: #f90;
    background-color: inherit;
    background: url(images/menu_indent_hover.gif) no-repeat left center;
}
.mainmenu a.menuSub,
.mainmenu a.menuSub:hover {
    margin-left: 10px;
    padding-left: 28px;
    background: url(images/menu_indent_sub.gif) no-repeat left center;
}
.usermenu a.highlight {
    color: #ff0000;
    background-color: inherit;
    background: url(images/menu_indent.gif) no-repeat left center;
}
.usermenu a.highlight:hover {
    color: #ff0000;
    background-color: inherit;
    background: url(images/menu_indent_hover.gif) no-repeat left center;
}

/* ------------ main content ------------ */
.centercolumn {
    float: left;
    text-align: left;
    line-height: 140%;
    background: #fff;
    color: inherit;
    overflow: hidden;
    width: 75%;
}
.centercolumn a {
    text-decoration: underline;
}
.centercolumn .block {
    margin: 0 25px;
}
.centerCcolumn .block,
.centerLcolumn .block,
.centerRcolumn .block {
    margin: 0;
}
.centerCcolumn .blockTitle,
.centerLcolumn .blockTitle,
.centerRcolumn .blockTitle {
    clear: both;
    color: #6075aa;
    width: 100%;
    margin: 8px 0 3px;
    padding: 3px 0px 4px 26px;
    font: bold 120% / normal "Trebuchet MS", "Lucida Grande", Verdana, Arial, "Hiragino Kaku Gothic Pro", osaka, sans-serif;
    background: #fff url(images/indent_h3.gif) no-repeat left top;
}

.centerCcolumn .blockContent {
    line-height: 150%;
    margin-bottom: 5px;
}
.centerLcolumn {
    float: left;
    width: 49%;
    padding-bottom: 5px;
    overflow: hidden;
}
.centerLcolumn .blockContent {
    padding: 3px 8px 8px 3px;
    line-height: 140%;
    margin-bottom: 5px;
}
.centerRcolumn {
    float: right;
    width: 49%;
    padding-bottom: 5px;
    overflow: hidden;
}
.centerRcolumn .blockContent {
    padding: 3px 3px 8px 8px;
    line-height: 140%;
    margin-bottom: 5px;
}

/* ------------ left column ------------ */
.leftcolumn {
    float: left;
    overflow: hidden;
    width: 25%;
    background: #f6f8fe url(images/side_back.gif) repeat-x top;
    color: inherit;
}
.leftcolumn .blockTitle {
    font-weight: bold;
    color: #fff;
    padding: 18px;
    width: 100%;
    background: #cfd5e9 url(images/block_title1.gif) repeat-x left center;
}
.leftcolumn .blockContent {
    margin: 5px 10px;
    line-height: 150%;
}
/* ------------ right column ------------ */
.rightcolumn {
    float: left;
    overflow: hidden;
    width: 19.9%;
    background: #f6f8fe;
    color: inherit;
}
.rightcolumn .blockTitle {
    font-weight: bold;
    color: #fff;
    padding: 18px;
    width: 100%;
    background: #cfd5e9 url(images/block_title2.gif) repeat-x left center;
}
.rightcolumn .blockContent {
    padding: 5px 10px;
    line-height: 150%;
}
/* ------------ for table ------------ */
.outer {
    border: 1px solid #eee;
}
th {
    padding: 3px;
    vertical-align: middle;
    font-weight: normal;
    color: #666;
    border-bottom: 1px solid #ccc;
    background: #eee;
}
.head {
    padding: 5px;
    color: inherit;
    border-bottom: 1px solid #cacaca;
    background: #f2f2f2;
}
.even {
    padding: 2px;
    color: inherit;
    border-bottom: 1px solid #cacaca;
    background: #f7f7f7;
}
.odd {
    padding: 2px;
    color: inherit;
    border-bottom: 1px solid #cacaca;
    background: #fafafa;
}
.foot {
    padding: 5px;
    font-weight: bold;
}
tr.even > td {
    padding: 2px;
    border-bottom: 1px solid #cacaca;
}
tr.odd > td {
    padding: 2px;
    border-bottom: 1px solid #cacaca;
}
tr.even {
    color: inherit;
    background: #f7f7f7;
}
tr.odd {
    color: inherit;
    background: #fafafa;
}
tr.odd:hover,
tr.even:hover {
    color: #000;
    border-bottom: 1px solid #cacaca;
    background: #dfdfdf;
}

/* ------------ error message & quote and code ------------ */
div.errorMsg {
    background: #f5e8e8;
    color: #ff0000;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #ccc;
}
div.confirmMsg {
    background: #eff4df;
    color: #003300;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #ccc;
}
div.resultMsg {
    background-color: #fff;
    color: #333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #ccc;
}
div.xoopsCode {
    background: #efefef;
    color: inherit;
    border: 1px solid #ccc;
    padding: 6px;
    overflow: auto;
    width: 95%;
}
div.xoopsQuote {
    background: #fdfdfd;
    color: inherit;
    border-left: 5px solid #ddd;
    overflow: auto;
    width: 90%;
    margin-left: 15px;
}
/* ------------ for comment area ------------ */
.formButton {
    border: 1px outset #616655;
    background-color: #eeeeee;
    color: #000000;
}
/* ------------ for comment ------------ */
.comTitle {
    margin-bottom: 2px;
}
.comText {
    padding: 2px;
}
.comUserStat {
    font-size: 80%;
    border: 1px solid #ccc;
    margin: 2px;
    padding: 2px;
    background: #fff;
    color: inherit;
}
.comUserStatCaption {
    font-weight: normal;
}
.comUserRank {
    margin: 2px;
}
.comUserRankText {
    font-size: 80%;
}
.comUserImg {
    margin: 2px;
}
.comDate {
    font-size: 80%;
}
.comDateCaption {
}
/* ------------ for bulletin and news module ------------ */
.item {
    overflow: hidden;
}
.itemHead {
    padding: 2px 5px 2px 20px;
    font-weight: bold;
    border-bottom: 1px dotted #ccc;
}
.itemInfo {
    text-align: right;
    padding: 3px 8px;
}
.itemTitle a {
    font-size: 120%;
    font-weight: bold;
    font-variant: small-caps;
    text-decoration: none;
}
.itemPoster {
    font-size: 90%;
}
.itemPostDate {
    font-size: 90%;
}
.itemStats {
    font-size: 90%;
}
.itemBody {
    padding: 0 8px 0 13px;
    overflow: hidden;
}
.itemText {
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 1.5em;
}
.itemText:first-letter {
    font-size: 1.5em;
    font-weight: bold;
}
.itemFoot {
    clear: both;
    text-align: right;
    padding: 8px;
    background: #efefef;
    color: inherit;
}
.itemAdminLink {
    font-size: 90%;
}
.itemPermaLink {
    font-size: 90%;
}
.itemBody a img {
    margin: 0 2px 3px 0;
    padding: 2px;
    border: 1px solid #ccc;
}

#main .d3f_head_h1,
#main .pico_menu h1 {
    padding: 7px 0 7px 33px;
    background-color: inherit;
}
#main .d3f_head_h2,
#main .pico_menu h2 {
    padding: 6px 0 6px 30px;
    background-color: inherit;
}
#main .d3f_head_h3,
#main .pico_menu h3 {
    padding: 5px 0 5px 28px;
    background-color: inherit;
}
