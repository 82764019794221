div.pico_breadcrumbs {
    font-size: 95%;
    padding: 0 0 3px;
    border-bottom: 1px #aaa solid;
}

.pico_body {
    margin: 0 -2px;
    padding: 15px 8px;
}

div.pico_menu h1 {
    margin: 18px 0 0 0;
    padding: 3px;
    background-color: #eee;
}
div.pico_menu h2 {
    margin: 18px 0 0 10px;
    padding: 3px;
    background-color: #eee;
}
div.pico_menu h3 {
    margin: 18px 0 0 20px;
    padding: 3px;
    background-color: #eee;
}
div.pico_menu h4 {
    margin: 18px 0 0 30px;
    padding: 3px;
    background-color: #eee;
}
div.pico_menu h5 {
    margin: 18px 0 0 40px;
    padding: 3px;
    background-color: #eee;
}
div.pico_menu div.level1 {
    margin-left: 0;
}
div.pico_menu div.level2 {
    margin-left: 10px;
}
div.pico_menu div.level3 {
    margin-left: 20px;
}
div.pico_menu div.level4 {
    margin-left: 30px;
}
div.pico_menu div.level5 {
    margin-left: 40px;
}

em.pico_notice {
    font-weight: bold;
    font-style: normal;
    color: #ff0000;
}

div.pico_controllers_in_menu {
    float: right;
    position: relative;
    top: -1.5em;
}

div.bottom_of_content_body {
    clear: right;
}

ul.pico_list_contents li {
    list-style: none outside;
}
ul.pico_list_contents_in_menu li {
    list-style: none outside;
}

div.pico_print_icon {
    float: right;
    width: 40px;
    height: 40px;
}
div.pico_tellafriend_icon {
    float: right;
    width: 40px;
    height: 40px;
}
div.pico_vote form {
    display: inline;
}

table.pico_pagenavigation {
    border-top: 1px #aaa solid;
    margin-top: 20px;
}

table.pico_form_table th.pico_waiting {
    color: red;
}
table.pico_form_table td.pico_waiting {
    color: red;
    background-color: white;
}

table.pico_form_table td,
table.pico_form_table th {
    color: black;
    vertical-align: top !important;
    text-align: left;
}
p.pico_submit {
    text-align: center;
    margin: 0;
    padding: 10px;
}

input.pico_ascii_only {
    ime-mode: disabled;
}
input.pico_number_only {
    ime-mode: disabled;
    text-align: right;
}

pre.pico_history_diff del {
    color: red;
}
pre.pico_history_diff ins {
    color: blue;
}

div.pico_pagebreak {
    margin: 10px;
}
div.pico_pagebreak span {
    border: 1px solid black;
    padding: 2px;
    margin: 2px;
}
div.pico_pagebreak span.selected {
    border: 0px;
}
